import { useState } from 'react';
import { Button, FormControl, InputGroup } from "react-bootstrap";

const SearchBar = ({ onSubmit }) => {
    const [searchString, setSearchString] = useState('')

    return (
        <InputGroup className="m-3">
            <FormControl placeholder="Author Name" onChange={(e) => setSearchString(e.target.value)} onKeyPress={(e) => { if (e.key === "Enter") { onSubmit(searchString) } }} />
            <Button type="submit" onClick={() => onSubmit(searchString)} >SEARCH</Button>
        </InputGroup>
    );
}

export default SearchBar;
