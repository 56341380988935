import { Card, Pagination } from "react-bootstrap";
import Paper from "./Paper"

const AuthorPapers = ({ papers, page, setPage }) => {
    return (
        <Card className="authorPapers">
            <div>
                {papers.slice(10 * page, 10 * (page + 1)).map(
                    (paper) => <Paper key={paper.paperId} paper={paper} />
                )}
            </div>
            <Pagination className="navigation">
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev onClick={() => setPage(page > 0 ? page - 1 : page)} />
                <Pagination.Item active>{page + 1} / {Math.ceil(papers.length / 10)}</Pagination.Item>
                <Pagination.Next onClick={() => setPage(10 * (page + 1) < papers.length ? page + 1 : page)} />
                <Pagination.Last onClick={() => setPage(Math.floor((papers.length - 1) / 10))} />
            </Pagination>
        </Card>
    );
}

export default AuthorPapers;
