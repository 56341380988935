import { Card } from "react-bootstrap";

const AuthorMetrics = ({ author }) => {
    console.log(author)

    return (
        <Card className="authorMetrics">
            <Card.Body>
                <Card.Title onClick={() => window.open(author.url, '_blank')} className="authorName">
                    {author.name}
                </Card.Title>
                <table className="metricsTable">
                    <tbody>
                        <tr>
                            <td>publications</td>
                            <td>{author.metrics.publications}</td>
                        </tr>
                        <tr>
                            <td>citations</td>
                            <td>{author.metrics.citations}</td>
                        </tr>
                        <tr>
                            <td>h-index</td>
                            <td>{author.metrics.hIndex}</td>
                        </tr>
                        <tr>
                            <td>h-frac</td>
                            <td>{author.metrics.hFrac}</td>
                        </tr>
                        <tr>
                            <td>CAP</td>
                            <td>{author.metrics.cap}</td>
                        </tr>
                    </tbody>
                </table>
                <p style={{ width: '210px', fontSize: 'smaller' }}>
                    <br></br>
                    (CAP is computed on publications from {new Date().getFullYear() - 7} to {new Date().getFullYear() - 3},
                    while all other measures here are based on all publications. All measures take into account the up-to-date citation counts.)
                </p>
            </Card.Body>
        </Card >
    );
}

export default AuthorMetrics;
