import AuthorMetrics from "./AuthorMetrics";
import AuthorPapers from "./AuthorPapers";

const AuthorInfo = ({ author, page, setPage }) => {
    return (
        <div className="authorInfo"  >
            <AuthorMetrics author={author} />
            <AuthorPapers papers={author.papers} page={page} setPage={setPage} />
        </div>
    );
}

export default AuthorInfo;
