import { Card } from "react-bootstrap";

const CandidateBox = ({ candidate, onClick }) => {
    return (
        <Card onClick={onClick} className="candidateInfo">
            <Card.Body className="m-0 p-1" >
                <Card.Title className="m-0" style={{ fontSize: '14px' }}>
                    {candidate.name}
                </Card.Title>
                <ul>
                    <li>{candidate.researchArea}</li>
                    <li>{candidate.paperCount} publications</li>
                    <li>{candidate.citationCount} citations</li>
                </ul>
            </Card.Body>
        </Card >
    );
}

export default CandidateBox;
