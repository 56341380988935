import { useState } from 'react';
import axios from 'axios';
import SearchBar from './components/SearchBar';
import CandidateList from './components/CandidateList';
import AuthorInfo from './components/AuthorInfo';
import { computePublications, computeCitations, computeHIndex, computeHFrac, computeCap } from './utils/Metrics';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';


const App = () => {
  const [author, setAuthor] = useState();
  const [page, setPage] = useState(0);
  const [candidates, setCandidates] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  const computeMetrics = (papers) => {
    return {
      publications: computePublications(papers),
      citations: computeCitations(papers),
      hIndex: computeHIndex(papers),
      hFrac: computeHFrac(papers),
      // as discussed: compute cap from last year
      cap: computeCap(papers, new Date().getFullYear() - 1)
    }
  };

  const getResearchArea = (papers) => {
    const counts = {};
    papers.forEach(paper => paper.fieldsOfStudy && paper.fieldsOfStudy.forEach(fos => counts[fos] = (counts[fos] || 0) + 1));
    let max_count = 0;
    let researchArea = 'n/a';
    for(let fos in counts) {
      if(counts[fos] > max_count) {
        max_count = counts[fos];
        researchArea = fos;
      }
    }
    return researchArea;
  };

  const search = (searchString) => {
    setCandidates([]);
    setAuthor();
    setPage(0);
    setShowNoResult(false);
    setShowAlert(false);

    if (searchString.length === 0) {
      return;
    }

    // console.log('search: ' + searchString);

    setIsSearching(true);

    axios.get('https://api.semanticscholar.org/graph/v1/author/search?fields=authorId,name,paperCount,citationCount,papers.fieldsOfStudy&limit=10&query=' + searchString)
      .then(response => {
        console.log(response);

        const tmp = [];
        for (let i = 0; i < response.data.data.length; i++) {
          const researchArea = getResearchArea(response.data.data[i].papers);
          tmp.push({ name: response.data.data[i].name, id: response.data.data[i].authorId, paperCount: response.data.data[i].paperCount, citationCount: response.data.data[i].citationCount, researchArea: researchArea });
        }

        if (tmp.length === 0) {
          setShowNoResult(true);
        } else {
          setCandidates(tmp);
        }

        setIsSearching(false);
      });
  }

  const select = (id) => {
    // console.log('get ' + id);

    setAuthor();
    setPage(0);
    setIsGetting(true);

    axios.get(`https://api.semanticscholar.org/graph/v1/author/${id}?fields=authorId,name,affiliations,url,paperCount,citationCount,hIndex,papers.paperId,papers.url,papers.title,papers.venue,papers.year,papers.authors,papers.citationCount`)
      .then((response) => {
        // console.log(response);
        const author = response.data;
        author.papers.sort((a, b) => a.citationCount > b.citationCount ? -1 : 1);
        author.metrics = computeMetrics(author.papers);

        setAuthor(author);
        setPage(0);
      })
      .catch((error) => {
        console.log(error);
        setShowAlert(true);
      })
      .then(() => setIsGetting(false));
  }

  return (
    <Container>
      <Row>
        <Col>
          <SearchBar onSubmit={search} />
        </Col>
      </Row>
      {showNoResult ? <Row>
        <Col>
          <Alert variant="primary" onClose={() => setShowNoResult(false)} dismissible>
            No authors found.
          </Alert>
        </Col>
      </Row> : ''}
      {showAlert ? <Row>
        <Col>
          <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
            Something went wrong. Please try again in a few minutes.
          </Alert>
        </Col>
      </Row> : ''}
      <Row>
        <Col style={{ display: "flex", justifyContent: 'center' }}>
          {isSearching ? <Spinner animation="border" variant='primary' /> :
            <CandidateList onClick={select} candidates={candidates} />}
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "30px", display: "flex", justifyContent: 'center' }}>
          {isGetting ? <Spinner animation="border" variant='primary' /> : ''}
          {author ? <AuthorInfo author={author} page={page} setPage={setPage} /> : ''}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
