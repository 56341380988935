const Paper = ({ paper }) => {
    let additional = '';

    if (paper.authors.length === 4) {
        additional = ', ' + paper.authors[3].name
    } else if (paper.authors.length > 4) {
        additional = ', +' + (paper.authors.length - 3) + ' authors'
    }

    return (
        <div className="paper" onClick={() => window.open(paper.url, '_blank')}>
            <div className='info'>
                <h5>{paper.title}</h5>
                <p>{paper.authors.slice(0, 3).map((author) => author.name).join(', ')}
                    {additional}
                </p>
                <p>{paper.venue ? paper.venue + ', ' : ''} {paper.year}</p>
            </div>
            <div className='citations'>
                <p>cited: {paper.citationCount}</p>
            </div>
        </div>
    );
}

export default Paper;
