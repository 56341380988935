import CandidateBox from "./CandidateBox";

const CandidateList = ({ candidates, onClick }) => {
    return (
        <div className="candidateContainer">
            {candidates.map((candidate) => <CandidateBox key={candidate.id} candidate={candidate} onClick={() => onClick(candidate.id)} />)}
        </div>
    );
}

export default CandidateList;
