// IMPORTANT: All functions assume that the papers are sorted by citationCount (in descending order).

export const computePublications = (papers) => {
    return papers.length;
};

export const computeCitations = (papers) => {
    return papers.reduce((c, p) => c + p.citationCount, 0);
};

export const computeHIndex = (papers) => {
    let h = 0;

    for (const paper of papers) {
        if (paper.citationCount > h) {
            h = h + 1;
        } else {
            break;
        }
    }

    return h;
};

export const computeHFrac = (papers) => {
    const tmp = papers.map((paper) => { return { citationCount: paper.authors.length > 0 ? paper.citationCount / paper.authors.length : 0 } });

    tmp.sort((a, b) => a.citationCount > b.citationCount ? -1 : 1);

    return computeHIndex(tmp);
};

export const computeCap = (papers, year) => {
    const tmp = papers.filter((paper) => paper.year >= year - 6 && paper.year <= year - 2);

    const P = tmp.length;
    let cap = 0;

    for (const paper of tmp) {
        if (paper.citationCount - paper.authors.length - P >= 0) {
            cap = cap + 1;
        }
    }

    return cap;
};
